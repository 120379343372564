import { AVAILABLE_ENTITIES, utils } from '../../../database'
import Autocomplete, {
  autocompleteDefaultOnChangeSavingID,
  autocompleteDomainValueOptionLabelSavingID,
  autocompleteDomainValueOptionLabelSavingIDUsingDescription,
  autocompleteOptionLabelUsingCustomFieldSavingID
} from '../../../components/material/Autocomplete'
import Button, { COLORS } from '../../../components/material/Button'
import {
  CONDITIONAL_REQUIRED_FIELD_TYPE,
  FIELD_TYPE,
  REQUIRED_FIELD_MESSAGES,
  isDateNullOrValid,
  missingRequiredFieldChecker,
  requiredFieldsEvaluator
} from '../../../utils/formHelper'
import DateField, {
  datefieldDefaultOnChange,
  datefieldRangedDefaultOnChange,
  hoursfieldDefaultOnChange
} from '../../../components/material/DateField'
import React, { Component } from 'react'
import TextField, {
  textfieldDefaultOnChange
} from '../../../components/material/TextField'

import HoursField from '../../../components/material/HoursField'
import { Q } from '@nozbe/watermelondb'
import _ from 'lodash'
import fnsformat from 'date-fns/format'
import moment from 'moment'
import ptBr from 'date-fns/locale/pt-BR'
import { withRouter } from 'react-router-dom'

class BatchForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ...props.initialValues,
      dataPartos_start: props.batch && props.batch.dataPartosInicio,
      dataPartos_end: props.batch && props.batch.dataPartosFim,
      dataIatf: props.batch && props.batch.dataIatf,
      isNovilha:
        props.batch && props.novilha
          ? props.batch.ordemMatriz === props.novilha.id
          : false,
      shouldDisableInduction:
        props.batch && props.batch.inducaoPuberdade && props.noInduction
          ? props.batch.inducaoPuberdade === props.noInduction.id
          : false,
      nextIatfDay: null,
      horaManejo: props.batch && props.batch.horaManejo
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.autocompleteDefaultOnChangeSavingID = autocompleteDefaultOnChangeSavingID.bind(
      this
    )
    this.autocompleteDomainValueOptionLabelSavingID = autocompleteDomainValueOptionLabelSavingID.bind(
      this
    )
    this.autocompleteDomainValueOptionLabelSavingIDUsingDescription = autocompleteDomainValueOptionLabelSavingIDUsingDescription.bind(
      this
    )
    this.autocompleteOptionLabelUsingCustomFieldSavingID = autocompleteOptionLabelUsingCustomFieldSavingID.bind(
      this
    )
    this.datefieldDefaultOnChange = datefieldDefaultOnChange.bind(this)
    this.datefieldRangedDefaultOnChange = datefieldRangedDefaultOnChange.bind(
      this
    )
    this.hoursfieldDefaultOnChange = hoursfieldDefaultOnChange.bind(this)
    this.textfieldDefaultOnChange = textfieldDefaultOnChange.bind(this)
    this.missingRequiredFieldChecker = missingRequiredFieldChecker.bind(this)
    this.requiredFieldsEvaluator = requiredFieldsEvaluator.bind(this)
    this.isDateNullOrValid = isDateNullOrValid.bind(this)
    this.calculateIatfDay = this.calculateIatfDay.bind(this)
  }

  onEnterPress = e => {
    if (e.key === 'Enter' && e.shiftKey === false && this.props.user.roles[0] !== 'Cliente (somente visualização)') {
      e.preventDefault()
      this.onSubmit()
    }
  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  onSubmit() {
    let payload = {
      ...this.props.batch,
      ..._.pick(this.state, [
        'nomeLote',
        'usuarioId',
        'protocoloId',
        'dataIatf',
        'periodoManejo',
        'progesteronaPosIATF',
        'vacinaReprodutivaLote',
        'dispositivo',
        'numeroUsoDispositivo',
        'racaMatriz',
        'ordemMatriz',
        'idadeMatriz',
        'mineral',
        'momentoSuplementacaoNovilhas',
        'momentoSuplementacaoVacas',
        'racaBezerro',
        'sexoBezerro',
        'inducaoPuberdade',
        'metodoInducaoPuberdade',
        'horaManejo'
      ]),
      retiroId: this.props.corral.id,
      isResync: this.props.batch ? this.props.batch.isResync : false
    }

    if (
      this.state.dataPartos_start &&
      this.state.dataPartos_start.isValid &&
      this.state.dataPartos_start.isValid()
    ) {
      payload.dataPartosInicio = this.state.dataPartos_start
      if (
        this.state.dataPartos_end &&
        this.state.dataPartos_end.isValid &&
        this.state.dataPartos_end.isValid()
      ) {
        payload.dataPartosFim = this.state.dataPartos_end
      } else {
        payload.dataPartosFim = this.state.dataPartos_start
      }
    }

    let requiredFiedsState = this.requiredFieldsEvaluator();
    let validDates =
      this.isDateNullOrValid(this.state.dataPartos_start) &&
      this.isDateNullOrValid(this.state.dataPartos_end) &&
      this.isDateNullOrValid(this.state.dataIatf)
    if (requiredFiedsState !== null || !validDates) {
      this.setState(requiredFiedsState)
    } else {
      if (this.props.onSubmit) {
        this.props.onSubmit(payload)
      }
    }
  }

  async calculateIatfDay(dataD0, protocoloId) {
    this.setState({ dataIatf: dataD0 })
    if (!dataD0 || !dataD0._isValid) {
      this.setState({ nextIatfDay: null })
    }
    if (dataD0 && dataD0._isValid && protocoloId) {
      let thisNextIatfDay = null
      let protocol =
        protocoloId &&
        (await utils.getElement(
          AVAILABLE_ENTITIES.PROTOCOLS,
          protocoloId
        ))

      let managementIds = protocol.managementProtocols.map(
        manejo => manejo.day
      )
      let daysManagement = await utils.getWithParam(
        AVAILABLE_ENTITIES.DOMAIN_VALUES,
        'id',
        Q.oneOf(managementIds)
      )

      let daybatch = daysManagement
        ? parseInt(
          daysManagement
            .sort(
              (a, b) =>
                parseInt(b.valor.replace(/\D/g, '')) -
                parseInt(a.valor.replace(/\D/g, ''))
            )[0]
            .valor.replace(/\D/g, '')
        )
        : 0
      thisNextIatfDay = moment.utc(
        dataD0.valueOf() +
        (daybatch) * 24 * 3600 * 1000
      )

      this.setState({
        nextIatfDay: thisNextIatfDay
      })
    }
  }

  renderNextDayIAT() {
    return <small className="small-date">
      <strong>Data da IATF 1:</strong> {this.state.nextIatfDay.format('DD/MM/YYYY')} {''}   ({fnsformat(Date.parse(this.state.nextIatfDay), 'EEEEEEE', { locale: ptBr })})
    </small>
  }

  render() {
    return (
      <div className="grid grid-batch-form">
        <div className="grid-item p-12 t-7 d-5">
          <TextField
            id='nomeLote'
            label='Nome do Lote'
            onChange={this.textfieldDefaultOnChange}
            onKeyDown={this.onEnterPress}
            value={this.state.nomeLote || null}
            error={!_.isEmpty(this.state.nomeLote_error)}
            helperText={this.state.nomeLote_error}
            disabled={this.props.viewOnly}
            autoFocus
          />
        </div>

        <div className="grid-item p-12 t-5 d-3">
          <DateField
            id='dataPartos'
            initialValues={[
              this.state.dataPartos_start,
              this.state.dataPartos_end
            ]}
            label='Data dos Partos'
            onChange={this.datefieldRangedDefaultOnChange('dataPartos')}
            onKeyDown={this.onEnterPress}
            error={
              !_.isEmpty(this.state.dataPartos_error) ||
              !this.isDateNullOrValid(this.state.dataPartos_start) ||
              !this.isDateNullOrValid(this.state.dataPartos_end)
            }
            helperText={this.state.dataPartos_error}
            disabled={this.props.viewOnly}
            ranged
          />
        </div>

        <div className="grid-item p-12 t-12 d-4">
          <Autocomplete
            id='usuarioId'
            options={this.props.relatedUsers}
            value={this.state.usuarioId || null}
            onChange={this.autocompleteDefaultOnChangeSavingID('usuarioId')}
            onKeyDown={this.state.usuarioId && this.onEnterPress}
            getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingID(
              this.props.relatedUsers,
              'nomeCompleto'
            )}
            useDefaultOptionSelected
            label='Responsável'
            error={!_.isEmpty(this.state.usuarioId_error)}
            helperText={this.state.usuarioId_error}
            disabled={this.props.viewOnly}
          />
        </div>

        <div className="grid-item p-12">
          <h2>Protocolo</h2>
        </div>

        <div className="grid-item p-12 t-6 d-4">
          <Autocomplete
            id='protocoloId'
            options={this.props.protocols}
            value={this.state.protocoloId || null}
            onChange={(e, value) => {
              this.autocompleteDefaultOnChangeSavingID('protocoloId')(
                e,
                value
              )
              const implant = _.isEmpty(value)
                ? null
                : _.find(
                  value.managementProtocols,
                  protocol => !_.isEmpty(protocol.implantId)
                )
              this.setState({
                dispositivo: implant && implant.implantId,
                dispositivo_error:
                  implant && implant.implantId
                    ? null
                    : REQUIRED_FIELD_MESSAGES.DEFAULT
              })
              // this.state.dataIatf ? this.setState({ protocoloId: value.id }) : null
              this.state.dataIatf && this.calculateIatfDay(this.state.dataIatf, value.id)
            }
            }
            onKeyDown={this.state.protocoloId && this.onEnterPress}
            getOptionLabel={this.autocompleteOptionLabelUsingCustomFieldSavingID(
              this.props.protocols,
              'name'
            )}
            useDefaultOptionSelected
            label='Protocolo'
            error={!_.isEmpty(this.state.protocoloId_error)}
            helperText={this.state.protocoloId_error}
            disabled={
              this.props.viewOnly ||
              this.props.initialValues.iatfCount > 1 ||
              (_.isEmpty(this.props.initialValues) && this.props.initialValues.protocoloId != null) ||
              (!_.isEmpty(this.props.initialValues) &&
                this.props.initialValues.prevDay !== undefined &&
                this.props.initialValues.prevDay?.valor !== "D0")
            }
          />

        </div>

        <div className="grid-item p-12 t-6 d-4">
          <DateField
            id='dataIatf'
            label='Data do inicio do protocolo (D0 - IATF 1)'
            value={this.state.dataIatf || null}
            onChange={(e) => this.calculateIatfDay(e, this.state.protocoloId)}
            onKeyDown={this.onEnterPress}
            error={
              !_.isEmpty(this.state.dataIatf_error) ||
              !this.isDateNullOrValid(this.state.dataIatf)
            }
            helperText={this.state.dataIatf_error}
            disabled={
              this.props.viewOnly ||
              (this.props.batch && this.props.batch.parentBatchId != null) ||
              (!this.props.initialValues.nextDay && this.props.batch) || this.props.initialValues.iatfDay > 1
            }
          />

          {this.state.nextIatfDay && this.renderNextDayIAT()}
        </div>

        <div className="grid-item p-12 t-6 d-4">
          <Autocomplete
            id='periodoManejo'
            options={this.props.periodoManejo}
            value={this.state.periodoManejo || null}
            onChange={this.autocompleteDefaultOnChangeSavingID(
              'periodoManejo'
            )}
            onKeyDown={this.state.periodoManejo && this.onEnterPress}
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
              this.props.periodoManejo
            )}
            useDefaultOptionSelected
            label='Período do Manejo'
            error={!_.isEmpty(this.state.periodoManejo_error)}
            helperText={this.state.periodoManejo_error}
            disabled={this.props.viewOnly}
          />
        </div>

        <div className="grid-item p-12 t-6 d-4">
          <HoursField
            id='horaManejo'
            initialValues={[
              this.state.horaManejo
            ]}
            label='Horário dos Manejos'
            onChange={this.hoursfieldDefaultOnChange('horaManejo')}
            onKeyDown={this.onEnterPress}
            helperText={this.state.horaManejo}
            disabled={this.props.viewOnly}
          />
        </div>

        <div className="grid-item p-12 t-6 d-4">
          <Autocomplete
            id='progesteronaPosIATF'
            options={this.props.mgaPosIatf}
            value={this.state.progesteronaPosIATF || null}
            onChange={this.autocompleteDefaultOnChangeSavingID(
              'progesteronaPosIATF'
            )}
            onKeyDown={
              this.state.progesteronaPosIATF && this.onEnterPress
            }
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
              this.props.mgaPosIatf
            )}
            useDefaultOptionSelected
            label='Progesterona Pós IATF'
            error={!_.isEmpty(this.state.progesteronaPosIATF_error)}
            helperText={this.state.progesteronaPosIATF_error}
            disabled={this.props.viewOnly}
          />
        </div>

        <div className="grid-item p-12 t-12 d-4">
          <Autocomplete
            id='vacinaReprodutivaLote'
            options={this.props.castleMaster}
            value={this.state.vacinaReprodutivaLote || null}
            onChange={this.autocompleteDefaultOnChangeSavingID(
              'vacinaReprodutivaLote'
            )}
            onKeyDown={
              this.state.vacinaReprodutivaLote && this.onEnterPress
            }
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
              this.props.castleMaster
            )}
            useDefaultOptionSelected
            label='Vacina Reprodutiva'

            error={!_.isEmpty(this.state.vacinaReprodutivaLote_error)}
            helperText={this.state.vacinaReprodutivaLote_error}
            disabled={this.props.viewOnly}
          />
        </div>

        <div className="grid-item p-12">
          <h2>Dispositivo Intravaginal Predominante</h2>
        </div>

        <div className="grid-item p-12 t-4">
          <Autocomplete
            id='dispositivo'
            options={this.props.dispIntraVaginalPredominante}
            value={this.state.dispositivo || null}
            onChange={this.autocompleteDefaultOnChangeSavingID('dispositivo')}
            onKeyDown={
              this.state.dispIntraVaginalPredominante
              && this.onEnterPress
            }
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
              this.props.dispIntraVaginalPredominante
            )}
            useDefaultOptionSelected
            label='Dispositivo'
            error={!_.isEmpty(this.state.dispositivo_error)}
            helperText={this.state.dispositivo_error}
            disabled={this.props.viewOnly}
          />
        </div>

        <div className="grid-item p-12 t-4">
          <Autocomplete
            id='numeroUsoDispositivo'
            options={this.props.usoDispIntraVaginalPredominante}
            value={this.state.numeroUsoDispositivo || null}
            onChange={this.autocompleteDefaultOnChangeSavingID(
              'numeroUsoDispositivo'
            )}
            onKeyDown={
              this.state.numeroUsoDispositivo && this.onEnterPress
            }
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
              this.props.usoDispIntraVaginalPredominante
            )}
            useDefaultOptionSelected
            label='Uso'
            error={!_.isEmpty(this.state.numeroUsoDispositivo_error)}
            helperText={this.state.numeroUsoDispositivo_error}
            disabled={this.props.viewOnly}
          />
        </div>

        <div className="grid-item p-12">
          <h2>Matriz Predominante</h2>
        </div>

        <div className="grid-item p-12 t-4">
          <Autocomplete
            id='racaMatriz'
            options={this.props.racaMatriz}
            value={this.state.racaMatriz || null}
            onChange={this.autocompleteDefaultOnChangeSavingID('racaMatriz')}
            onKeyDown={this.state.racaMatriz && this.onEnterPress}
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingIDUsingDescription(
              this.props.racaMatriz
            )}
            useDefaultOptionSelected
            label='Raça'
            error={!_.isEmpty(this.state.racaMatriz_error)}
            helperText={this.state.racaMatriz_error}
            disabled={this.props.viewOnly}
          />
        </div>

        <div className="grid-item p-12 t-4">
          <Autocomplete
            id='ordemMatriz'
            options={this.props.ordemMatriz}
            value={this.state.ordemMatriz || null}
            onChange={(e, value) => {
              this.setState({
                ordemMatriz: value && value.id,
                ordemMatriz_error: this.missingRequiredFieldChecker(
                  'ordemMatriz',
                  value,
                  FIELD_TYPE.USING_ID
                ),
                isNovilha: value
                  ? value.descricao.toLowerCase() === 'novilhas'
                  : false,
                isSolteira: value
                  ? value.descricao.toLowerCase() === 'solteiras'
                  : false,
                momentoSuplementacaoNovilhas: null,
                momentoSuplementacaoNovilhas_erro: null,
                momentoSuplementacaoVacas: null,
                momentoSuplementacaoVacas_error: null,
                racaBezerro: null,
                racaBezerro_error: null,
                sexoBezerro: null,
                sexoBezerro_error: null,
                inducaoPuberdade: null,
                inducaoPuberdade_error: null,
                metodoInducaoPuberdade: null,
                metodoInducaoPuberdade_error: null
              })
            }}
            onKeyDown={this.state.ordemMatriz && this.onEnterPress}
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingIDUsingDescription(
              this.props.ordemMatriz
            )}
            useDefaultOptionSelected
            label='Ordem'
            error={!_.isEmpty(this.state.ordemMatriz_error)}
            helperText={this.state.ordemMatriz_error}
            disabled={this.props.viewOnly}
          />

        </div>

        <div className="grid-item p-12 t-4 d-3">
          <Autocomplete
            id='idadeMatriz'
            options={this.props.idadeMatriz}
            value={this.state.idadeMatriz || null}
            onChange={this.autocompleteDefaultOnChangeSavingID('idadeMatriz')}
            onKeyDown={this.state.idadeMatriz && this.onEnterPress}
            getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
              this.props.idadeMatriz
            )}
            useDefaultOptionSelected
            label='Idade (Meses)'
            error={!_.isEmpty(this.state.idadeMatriz_error)}
            helperText={this.state.idadeMatriz_error}
            disabled={this.props.viewOnly}
          />
        </div>

        <div className="grid-item p-12">
          <h2>Nutrição</h2>
        </div>

        {this.state.isNovilha ? (
          <div className="grid-item p-12 t-8 d-6">
            <Autocomplete
              id='momentoSuplementacaoNovilhas'
              options={this.props.momentoSuplementacaoNovilhas}
              value={this.state.momentoSuplementacaoNovilhas || null}
              onChange={this.autocompleteDefaultOnChangeSavingID(
                'momentoSuplementacaoNovilhas'
              )}
              onKeyDown={this.state.isNovilha && this.onEnterPress}
              getOptionLabel={this.autocompleteDomainValueOptionLabelSavingIDUsingDescription(
                this.props.momentoSuplementacaoNovilhas
              )}
              useDefaultOptionSelected
              label='Suplementação de Novilhas'
              error={
                !_.isEmpty(this.state.momentoSuplementacaoNovilhas_error)
              }
              helperText={this.state.momentoSuplementacaoNovilhas_error}
              disabled={this.props.viewOnly}
            />
          </div>
        ) : (
          <div className="grid-item p-12 t-8 d-6">
            <Autocomplete
              id='momentoSuplementacaoVacas'
              options={this.props.momentoSuplementacaoVacas}
              value={this.state.momentoSuplementacaoVacas || null}
              onChange={this.autocompleteDefaultOnChangeSavingID(
                'momentoSuplementacaoVacas'
              )}
              onKeyDown={
                this.state.momentoSuplementacaoVacas
                && this.onEnterPress
              }
              getOptionLabel={this.autocompleteDomainValueOptionLabelSavingIDUsingDescription(
                this.props.momentoSuplementacaoVacas
              )}
              useDefaultOptionSelected
              label='Suplementação de Vacas'
              error={!_.isEmpty(this.state.momentoSuplementacaoVacas_error)}
              helperText={this.state.momentoSuplementacaoVacas_error}
              disabled={this.props.viewOnly}
            />
          </div>
        )}

        <div className="grid-item p-12 t-4">
          <TextField
            id='mineral'
            label='Mineral (Nome comercial)'
            onChange={this.textfieldDefaultOnChange}
            onKeyDown={this.onEnterPress}
            value={this.state.mineral || null}
            error={!_.isEmpty(this.state.mineral_error)}
            helperText={this.state.mineral_error}
            disabled={this.props.viewOnly}
          />
        </div>

        {!this.state.isSolteira && !this.state.isNovilha && (
          <>
            <div className="grid-item p-12">
              <h2>Bezerro Predominante no Lote</h2>
            </div>

            <div className="grid-item p-12 t-4">
              <Autocomplete
                id='racaBezerro'
                options={this.props.racaBezerro}
                value={this.state.racaBezerro || null}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'racaBezerro'
                )}
                onKeyDown={this.state.racaBezerro && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingIDUsingDescription(
                  this.props.racaBezerro
                )}
                useDefaultOptionSelected
                label='Raça'
                error={!_.isEmpty(this.state.racaBezerro_error)}
                helperText={this.state.racaBezerro_error}
                disabled={this.props.viewOnly}
              />
            </div>

            <div className="grid-item p-12 t-4">
              <Autocomplete
                id='sexoBezerro'
                options={this.props.sexoBezerro}
                value={this.state.sexoBezerro || null}
                onChange={this.autocompleteDefaultOnChangeSavingID(
                  'sexoBezerro'
                )}
                onKeyDown={this.state.sexoBezerro && this.onEnterPress}
                getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                  this.props.sexoBezerro
                )}
                useDefaultOptionSelected
                label='Sexo'
                error={!_.isEmpty(this.state.sexoBezerro_error)}
                helperText={this.state.sexoBezerro_error}
                disabled={this.props.viewOnly}
              />
            </div>
          </>
        )
        }
        {
          this.state.isNovilha && (
            <>
              <div className="grid-item p-12">
                <h2>Indução de Puberdade</h2>
              </div>

              <div className="grid-item p-12 t-4">
                <Autocomplete
                  id='inducaoPuberdade'
                  options={this.props.inducaoPuberdade}
                  value={this.state.inducaoPuberdade || null}
                  onChange={(e, value) => {
                    const shouldDisableInduction = value.valor === 'Não'
                    this.autocompleteDefaultOnChangeSavingID('inducaoPuberdade')(
                      e,
                      value
                    )
                    this.setState({
                      shouldDisableInduction,
                      metodoInducaoPuberdade_error: null,
                      metodoInducaoPuberdade: null
                    })
                  }}
                  onKeyDown={
                    this.state.inducaoPuberdade && this.onEnterPress
                  }
                  getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                    this.props.inducaoPuberdade
                  )}
                  useDefaultOptionSelected
                  label='Indução de Puberdade'
                  error={!_.isEmpty(this.state.inducaoPuberdade_error)}
                  helperText={this.state.inducaoPuberdade_error}
                  disabled={this.props.viewOnly}
                />
              </div>

              <div className="grid-item p-12 t-4">
                <Autocomplete
                  id='metodoInducaoPuberdade'
                  options={this.props.metodoInducao}
                  value={this.state.metodoInducaoPuberdade || null}
                  onChange={this.autocompleteDefaultOnChangeSavingID(
                    'metodoInducaoPuberdade'
                  )}
                  onKeyDown={
                    this.state.metodoInducaoPuberdade && this.onEnterPress
                  }
                  getOptionLabel={this.autocompleteDomainValueOptionLabelSavingID(
                    this.props.metodoInducao
                  )}
                  useDefaultOptionSelected
                  label='Método de Indução'
                  error={!_.isEmpty(this.state.metodoInducaoPuberdade_error)}
                  helperText={this.state.metodoInducaoPuberdade_error}
                  disabled={
                    this.props.viewOnly || this.state.shouldDisableInduction
                  }
                />
              </div>
            </>
          )
        }

        <div className="grid-item p-display-flex p-justify-space-between p-12">
          <Button
            color={COLORS.GREY}
            label={this.props.viewOnly ? 'Voltar' : 'Cancelar'}
            onClick={this.onCancel}
          />
          {!this.props.viewOnly && (
            <Button
              label='Salvar'
              onClick={this.onSubmit}
              containerStyle={{ textAlign: 'right' }}
              disabled={this.props.user.roles[0] === 'Cliente (somente visualização)'}
            />
          )}
        </div>
      </div>
    )
  }
}

const propsFromDatabase = async props => {
  let allCastleMaster = await utils.getDomainValuesBy('Vacina Reprodutiva Lote')

  const castleMaster = [
    ...allCastleMaster
      .filter(x => ['Não', 'CattleMaster'].includes(x.valor))
      .sort((a, b) => {
        if (a.valor.toLowerCase() > b.valor.toLowerCase()) {
          return -1
        }

        if (a.valor.toLowerCase() < b.valor.toLowerCase()) {
          return 1
        }

        return 0
      }),
    ..._.sortBy(
      allCastleMaster.filter(x => !['Não', 'CattleMaster'].includes(x.valor)),
      [
        castleMaster => !castleMaster.valor.toLowerCase().startsWith('zoetis'),
        castleMaster => castleMaster.valor.toLowerCase()
      ]
    )
  ]

  const ordemMatriz = _.sortBy(
    await utils.getDomainValuesBy('Ordem da Matriz Predominante'),
    [
      ordem =>
        !ordem.descricao
          .trim()
          .toLowerCase()
          .startsWith('novilhas precoces'),
      ordem =>
        !ordem.descricao
          .trim()
          .toLowerCase()
          .startsWith('novilhas'),
      ordem =>
        !ordem.descricao
          .trim()
          .toLowerCase()
          .startsWith('primíparas precoces'),
      ordem =>
        !ordem.descricao
          .trim()
          .toLowerCase()
          .startsWith('primíparas'),
      ordem =>
        !ordem.descricao
          .trim()
          .toLowerCase()
          .startsWith('secundíparas precoces'),
      ordem =>
        !ordem.descricao
          .trim()
          .toLowerCase()
          .startsWith('secundíparas'),
      ordem =>
        !ordem.descricao
          .trim()
          .toLowerCase()
          .startsWith('multíparas'),
      ordem =>
        !ordem.descricao
          .trim()
          .toLowerCase()
          .startsWith('solteiras'),
      ordem => ordem.descricao.toLowerCase()
    ]
  )

  const inducaoPuberdade = await utils.getDomainValuesBy('Indução de Puberdade')

  return {
    requiredFields: [
      'nomeLote',
      'usuarioId',
      'protocoloId',
      'dataIatf',
      'periodoManejo',
      'progesteronaPosIATF',
      'vacinaReprodutivaLote',
      'dispositivo',
      'numeroUsoDispositivo',
      'racaMatriz',
      'ordemMatriz'
    ],
    conditionalRequiredFields: [
      {
        field: 'inducaoPuberdade',
        dependsOn: 'isNovilha',
        value: true,
        type: CONDITIONAL_REQUIRED_FIELD_TYPE.DEPENDENT_ON_FIELD
      },
      {
        field: 'metodoInducaoPuberdade',
        dependsOn: 'inducaoPuberdade',
        //TODO: Find a better way of dealing with this required field, instead of hardcoded ids
        value: '2f8f80a3-dd3c-44de-8de7-b552bb6fac18',
        type: CONDITIONAL_REQUIRED_FIELD_TYPE.DEPENDENT_ON_FIELD
      }
    ],
    ordemMatriz,
    racaMatriz: _.sortBy(
      await utils.getDomainValuesBy('Raça da Matriz predominante'),
      [raca => raca.valor.toLowerCase()]
    ),
    racaBezerro: _.sortBy(await utils.getDomainValuesBy('Raça do bezerro'), [
      raca => raca.valor.toLowerCase()
    ]),
    sexoBezerro: await utils.getDomainValuesBy('Sexo do Bezerro'),
    inducaoPuberdade,
    noInduction: _.find(inducaoPuberdade, { valor: 'Não' }),
    metodoInducao: await utils.getDomainValuesBy(
      'Método de Indução de Puberdade'
    ),
    suplementacao: await utils.getDomainValuesBy('Suplementação'),
    momentoSuplementacaoVacas: await utils.getDomainValuesBy(
      'Momento da Suplementação de Vacas'
    ),
    momentoSuplementacaoNovilhas: await utils.getDomainValuesBy(
      'Momento de Suplementação de Novilhas'
    ),
    mgaPosIatf: await utils.getDomainValuesBy('Progesterona Pos IATF'),
    castleMaster: castleMaster,
    periodoManejo: await utils.getDomainValuesBy('Periodo do Manejo'),
    dispIntraVaginalPredominante: _.sortBy(
      await utils.getDomainValuesBy('Dispositivo'),
      [
        implant => !implant.valor.toLowerCase().startsWith('zoetis'),
        implant => implant.valor.toLowerCase()
      ]
    ),
    usoDispIntraVaginalPredominante: _.sortBy(
      await utils.getDomainValuesBy('Tipo de Implante Predominante'),
      ['valor']
    ),
    idadeMatriz: _.sortBy(await utils.getDomainValuesBy('Idade da Matriz'), [
      idade => parseInt(idade.valor.split(' ')[0])
    ]),
    novilha: _.find(
      ordemMatriz,
      ordem => ordem.descricao.toLowerCase() === 'novilhas'
    )
  }
}

export default utils.withPropsFromDatabase(
  propsFromDatabase,
  withRouter(BatchForm)
)
